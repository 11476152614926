<template>
	<!-- <div style="height: 100%;width: 100%;touch-action: none;border: 1px solid;">
		<pre>{{ item }}</pre>
	</div> -->
	<div v-if="dataKey == null" class="fill-height fill-width">
		<canvas v-show="false" id="texture"></canvas>
		<canvas
			id="renderCanvas"
			style="height: 100%;width: 100%;touch-action: none;"
		>
		</canvas>
	</div>
	<div v-else class="fill-height fill-width">
		<canvas v-show="false" :id="'texture' + dataKey"></canvas>
		<canvas
			:id="'renderCanvas' + dataKey"
			style="height: 95%;width: 95%;touch-action: none;"
		>
		</canvas>
	</div>
</template>

<script>
import * as BABYLON from '@babylonjs/core/Legacy/legacy';
import "@babylonjs/core/Debug/debugLayer";
import "@babylonjs/inspector";
import "@babylonjs/loaders/glTF";
// material type
const MATERIAL_LEATHER = "Leather"
const MATERIAL_PVC = "PVC"
const MATERIAL_OTHERS = "Others"
export default{
	props:{
		// item:{
		// 	type: Object,
		// 	default: ()=>{
		// 		return {}
		// 	}
		// },
		partSpecs:{
			type: Array,
			default: ()=>{
				return []
			}
		},
		dataKey:{
			type: Number,
			default: ()=>{
				return null
			}
		},
		isZoom:{
			type: Boolean,
			default: ()=>{
				return false
			}
		}
	},
	data(){
		return {
			engine: null,
			scene: null,
			canvas : null,
			camera: null,
			leather_mat : null,
			// chairList : [
			// 	'seatmodel/AB0065.glb',
			// 	'seatmodel/AB0065.glb',
			// ],
			package_sockets : [],
			socketBtns : [],
			ori_chair_meshes: [],
			mainColor: new BABYLON.Color4.FromInts(210,209,192,1).toLinearSpace(),
			secondaryColor: null,
			logoDecal: null,
			logoMode: null,
			mousedown: false,
			mouseMove: false,
			logoMaterial: '',
			noColor: new BABYLON.Color4.FromInts(255,255,255,1).toLinearSpace(),
		}
	},
	mounted(){
		this.init()
		// this.loadChair(this.chairList[0])
		// this.loadShark()
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.resize);
		this.engine.stopRenderLoop();
		this.scene.dispose()
		this.scene = null
		this.engine = null
	},
	methods:{
		init(){
			const _this = this;
			if(this.dataKey == null)
				this.canvas = document.getElementById('renderCanvas');
			else
				this.canvas = document.getElementById('renderCanvas' + this.dataKey);

			// load the 3D engine
			this.engine = new BABYLON.Engine(this.canvas, true, { preserveDrawingBuffer: true, stencil: true });

			this.scene = this.createScene()
			this.scene.clearColor = new BABYLON.Color4(0, 0, 0, 0);

			this.leather_mat = new BABYLON.Texture("/Leather Mask.png", this.scene);

			// run the render loop
			this.engine.runRenderLoop(function(){
				_this.scene.render();
			});

			// the canvas/window resize event handler
			window.addEventListener('resize', function(){
				_this.engine && _this.engine.resize();
			});

			//init camera 2 for taking screenshots
			var position = BABYLON.Vector3.Zero()
			this.camera2 = new BABYLON.ArcRotateCamera("camera", Math.PI / 2, Math.PI / 2, 50, position, this.scene);
			this.camera2.minZ = 0.01;
			this.camera2.radius = 30;

			BABYLON.SceneOptimizer.OptimizeAsync(this.scene, BABYLON.SceneOptimizerOptions.LowDegradationAllowed(24),
				function() {
				}, function() {
				});
		},
		toggleInspector(){
			let inspector = this.scene.debugLayer
			if(inspector.isVisible() ){
				inspector.hide()
			}else{
				inspector.show({
					// embedMode: true,
				});
			}
		},
		createScene(){
			// create a basic BJS Scene object
			var scene = new BABYLON.Scene(this.engine);

			this.camera = new BABYLON.ArcRotateCamera("camera", Math.PI / 2, Math.PI / 2, 50, BABYLON.Vector3.Zero(), scene);
			// camera.upperRadiusLimit = 0.5;
			// camera.lowerRadiusLimit = 0.25;
			this.camera.minZ = 0.01;
			this.camera.radius = 30

			// target the camera to scene origin
			this.cameraZoomToZero()

			// attach the camera to the canvas
			this.cameraAttachControl()
			this.camera.inputs.remove(this.camera.inputs.attached.mousewheel);
			this.camera.lowerRadiusLimit = 5
			this.camera.upperRadiusLimit = 50

			this.camera.angularSensibilityX = 2000;
			this.camera.angularSensibilityY = 2000;

			// create a basic light, aiming 0,1,0 - meaning, to the sky
			var light = new BABYLON.HemisphericLight('light1', new BABYLON.Vector3(0,1,0), scene);
			light.intensity = 1.4;
			light.specular = new BABYLON.Color3(0.2,0.2,0.2);

			return scene;
		},
		cameraZoomToZero(){
			this.camera.setTarget(BABYLON.Vector3.Zero());
		},
		cameraAttachControl(){
			this.camera.attachControl(this.canvas, true);
		},
		cameraDetachControl(){
			this.camera.detachControl();
		},
		resize(){
			const _this = this;
			_this.engine.resize();
		},
		zoomCamera(val = 10){
			this.camera.radius = this.camera.radius + val
		},
		loadShark(){			
			let shark = new BABYLON.Mesh();
			BABYLON.SceneLoader.ImportMesh("", "https://cdn.glitch.com/", "fbe5330c-72c9-4613-8c7d-21b7376284bd%2FShark.glb?v=1541686223911", this.scene, function (newMeshes) {    
				shark = newMeshes[0]
			});
		},
		loadChair(obj_name){
			if(this.chair){
				this.chair.getChildMeshes().forEach((item)=>{
					item.material && item.material.dispose()
				})
				this.chair.dispose()
			}

			BABYLON.SceneLoader.ImportMeshAsync("", process.env.VUE_APP_BUCKET_DOMAIN, obj_name, this.scene).then((res)=>{

				this.chair = res.meshes[0]
				this.chair.position.x = 0.5
				this.chair.position.y = -10

				this.ori_chair_meshes = this.chair.getChildMeshes()
				this.generateSocketBtns()
				this.renderPackage() // render default leather material
				this.$emit('seat-ready')
			})
		},
		generateSocketBtns(){
			let hightlight = new BABYLON.HighlightLayer("hightlight", this.scene);
			let sockets = this.chair.getChildren().filter((item)=> item.id.startsWith('SOCKET'))
			sockets.forEach((item, index)=>{
				let socketBtn = BABYLON.MeshBuilder.CreateSphere('btn_'+item.id, {diameterX: 0.4, diameterY: 0.8, diameterZ: 0.8});
				socketBtn.parent = this.chair
				socketBtn.position = item.position.clone()
				socketBtn.rotationQuaternion = item.rotationQuaternion
				socketBtn.isVisible = false

				let zoom_point = BABYLON.MeshBuilder.CreateSphere('zoom_point_'+item.id, {diameterX: 0.2, diameterY: 0.8, diameterZ: 0.5});
				zoom_point.parent = this.chair
				zoom_point.isVisible = false
				zoom_point.position = item.position.clone()
				zoom_point.rotationQuaternion = item.rotationQuaternion
				zoom_point.translate(BABYLON.Axis.X, -15, BABYLON.Space.LOCAL);

				hightlight.addMesh(socketBtn, new BABYLON.Color3.FromInts(255,255,255) )
				// hightlight.innerGlow = false;
				socketBtn.actionManager = new BABYLON.ActionManager(this.scene);
				let _this = this
				socketBtn.actionManager.registerAction(
					new BABYLON.ExecuteCodeAction({
						trigger: BABYLON.ActionManager.OnPickTrigger,
					},
					function (e) {
						_this.zoomToSocket(e,zoom_point)
					})
				)
				socketBtn.zoom_point_id = 'zoom_point_'+item.id
				this.socketBtns.push(socketBtn)
				
				let hit_point = this.linkPartSocket(zoom_point)
				//Readjust the y axis of the hit point
				hit_point.pickedPoint.y = hit_point.pickedPoint.y - 10;

				let relatedMesh = hit_point.pickedMesh
				socketBtn.relatedMesh = relatedMesh
				relatedMesh.relatedSocketBtn = socketBtn
				relatedMesh.centre_point = hit_point
			})

			var alpha = 0;
			this.scene.registerBeforeRender(() => {
				alpha += 0.06;

				hightlight.blurHorizontalSize = 0.3 + Math.cos(alpha) * 0.6 + 0.6;
				hightlight.blurVerticalSize = 0.3 + Math.cos(alpha) * 0.6 + 0.6;
			});
		},
		vecToLocal(vector, mesh){
			var m = mesh.getWorldMatrix();
			var v = BABYLON.Vector3.TransformCoordinates(vector, m);
			return v;		 
		},
		linkPartSocket(zoomPoint, showRay = false){

			var origin = zoomPoint.absolutePosition;

			var forward = new BABYLON.Vector3(1,0,0);		
			forward = this.vecToLocal(forward, zoomPoint);

			var direction = forward.subtract(origin);
			direction = BABYLON.Vector3.Normalize(direction);

			var ray = new BABYLON.Ray(origin, direction, 25);
			if(showRay){
				let rayHelper = new BABYLON.RayHelper(ray);		
				rayHelper.show(this.scene);	
			}	

			let relatedPart = this.scene.pickWithRay(ray, function(mesh){
				return mesh != zoomPoint
			})
			
			return relatedPart
		},
		resetCameraPosition(){
			this.cameraZoomToZero()
			this.camera.position = new BABYLON.Vector3(0,0,30)
		},
		zoomToSocket(e = null, zoom_point = null, is_increase = true){
			let socketBtn = '', to = ''

			if(e != null && zoom_point != null)
			{
				socketBtn = e.meshUnderPointer
				to = zoom_point.absolutePosition
			}
			else
			{
				socketBtn = this.getNextSocketBtn(is_increase)
				to = this.scene.getMeshByID(socketBtn.zoom_point_id).absolutePosition
			}
			
			var speed = 100;
			var frameCount = 25;
			this.camera.target = socketBtn
			this.selectedSocket = socketBtn
			this.selectedMesh = socketBtn.relatedMesh
			this.selectedMesh_2 = this.scene.getMeshByID(this.selectedMesh.id + '.' + this.selectedMesh.id)
			this.animateCameraToPosition(this.camera, speed, frameCount, to)
			this.$emit('click-socket')

			const _this = this;
			_this.canvas.addEventListener("pointerdown", _this.checkMouseDown, true);
			_this.canvas.addEventListener("pointermove", _this.checkMouseMove, true);
			_this.canvas.addEventListener("pointerup", _this.checkMouseUp, true);
		},
		animateCameraToPosition(cam, speed, frameCount, newPos) {
			var ease = new BABYLON.SineEase();
			ease.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEINOUT);
			var aable2 = BABYLON.Animation.CreateAndStartAnimation('at4', cam, 'position', speed, frameCount, cam.position, newPos, 0, ease, this.camPosAnimEnded);
			aable2.disposeOnEnd = true;
		},
		targAnimEnded(){
			console.log("targAnimEnded")
		},
		camPosAnimEnded(){
			console.log("camPosAnimEnded")
		},
		setPackageSockets(relatedSockets){
			this.package_sockets = relatedSockets
		},
		renderPackage(){
			let part_specs = []
			this.ori_chair_meshes.forEach((item,key)=>{
				item.socketInfo = this.matchSocket(key)
				item.test = this.matchMaterialWithSocket(item)
				
				if(item.socketInfo)
				{
					switch(item.socketInfo.material_value)
					{
					case MATERIAL_PVC:
						item.material.albedoTexture = null
						break;
					case MATERIAL_OTHERS: // leather
						item.material.albedoTexture = this.leather_mat
						item.material.albedoTexture.uScale = 18;
						item.material.albedoTexture.vScale = 18;
						break;
					default: // leather
						item.material.albedoTexture = this.leather_mat
						item.material.albedoTexture.uScale = 18;
						item.material.albedoTexture.vScale = 18;
					}

					item.material.metallic = 0 

					if(item.socketInfo.is_customizable)
					{
						if(item.socketInfo.is_customizable == 1)
						{
							let part_spec = {
								material_no: item.socketInfo.material_no,
								material_no_web: item.socketInfo.material_no_web,
								name: item.socketInfo.material_name,
								part: item,
							}

							let selectedMesh_2 = this.scene.getMeshByID(item.id + '.' + item.id)

							if(selectedMesh_2)
								selectedMesh_2.dispose()

							part_specs.push(part_spec)
						}
					}
				}				
			})
			this.$emit("add-part-spec", part_specs)
			this.setSeatColor();
		},
		matchSocket(key){
			return this.package_sockets.find((item)=> item.material_no_web == key) || {}
		},
		matchMaterialWithSocket(mesh){
			if(mesh.relatedSocketBtn == null){
				return { material_value: null }
			}
			let package_sockets_without_back = this.package_sockets.filter((item)=> item.is_customizable == 1)

			return package_sockets_without_back.find((item)=> mesh.relatedSocketBtn.id.startsWith('btn_SOCKET_'+item.material_name))
		},
		checkOrdering(){
			return this.ori_chair_meshes.map(item=> {
				return {
					app: item.test?.material_no,
					material_name : item.socketInfo?.material_name,
				}
			}).map((item,key)=> {
				return {
					web: key,
					app : item.app,
					material_name : item.material_name,
				}}
			).sort((a,b)=>  - (b.app - a.app) )
		},
		setMainColor(color){
			if(color)
				this.mainColor = new BABYLON.Color4.FromInts(color.r, color.g, color.b, color.a).toLinearSpace()
			else
				this.mainColor = new BABYLON.Color4.FromInts(210,209,192, 1).toLinearSpace()

			this.setSeatColor()
		},
		setSecondaryColor(color){
			if(color)
				this.secondaryColor = new BABYLON.Color4.FromInts(color.r, color.g, color.b, color.a).toLinearSpace()
			else
				this.secondaryColor = new BABYLON.Color4.FromInts(210,209,192, 1).toLinearSpace()
				
		},
		setSeatColor(){
			if(!this.partSpecs.length > 0)
			{
				this.ori_chair_meshes.forEach((item,key)=>{
					item.material.albedoColor = this.mainColor
				})
			}
			else
			{
				this.ori_chair_meshes.forEach((item,key)=>{
					if(item.socketInfo.is_customizable == 1)
					{
						let currentPart = this.getPartSpec(item)
						if(!currentPart.special_material)
						{
							item.material.albedoColor = this.mainColor
						}		
					}
					else
					{
						item.material.albedoColor = this.mainColor
					}
						

				})
			}
			
		},
		getSocketBtns(){
			return this.socketBtns
		},
		getLogoableSocketBtns(){
			return this.socketBtns.filter((item)=> {
				if(item.relatedMesh.socketInfo)
					return item.relatedMesh.socketInfo.is_logo == 1
			})
		},
		setSocketBtnsVisible(socketBtns = [], isVisible = null){
			socketBtns.forEach((item)=>{
				item.isVisible = isVisible ?? !item.isVisible
			})
		},
		setLogoMaterial(material = null, selectedMesh = null)
		{
			this.logoMaterial = material
			if(selectedMesh)
				this.selectedMesh = selectedMesh
				
			if(this.logoDecal == null)
				this.setInitLogo()
		},
		enableStampLogoMode(){
			this.logoMode = true
			const _this = this;
			this.canvas.addEventListener("pointermove", this.onStampLogo, true);

			this.scene.onDispose = function () {
				_this.canvas.removeEventListener("pointerdown", _this.onStampLogo);
			}
		},
		enableLogoMode(){
			this.logoMode = true

			const _this = this;
			this.canvas.addEventListener("pointerdown", ()=>{
				_this.mousedown = true
			}, true);
			this.canvas.addEventListener("pointermove", this.onDragLogo, true);

			this.canvas.addEventListener("pointerup", ()=>{
				_this.onDragLogo()
				_this.mousedown = false
				_this.cameraAttachControl()
			}, true);

			this.scene.onDispose = function () {
				_this.canvas.removeEventListener("pointermove", _this.onDragLogo, true);
				_this.canvas.removeEventListener("pointerup", ()=>{
					_this.onDragLogo()
					_this.mousedown = false
				}, true);
				_this.canvas.removeEventListener("pointerdown", ()=>{
					_this.mousedown = true
				}, true);
			}
		},
		disabledLogoMode(){
			this.logoMode = false
			const _this = this;
			this.canvas.removeEventListener("pointermove", this.onDragLogo, true);
			this.canvas.removeEventListener("pointerup", ()=>{
				_this.onDragLogo()
				_this.mousedown = false
			}, true);
			this.canvas.removeEventListener("pointerdown", ()=>{
				_this.mousedown = true
			}, true);
		},
		onStampLogo(evt){
			const _this = this;
			let selectedPart = this.selectedMesh

			if (evt.button !== 0) {
				return null;
			}

			var materialPlane = new BABYLON.StandardMaterial("texturePlane", this.scene);
			materialPlane.diffuseTexture = new BABYLON.Texture(this.logoMaterial, this.scene);
			materialPlane.diffuseTexture.hasAlpha = true;
			materialPlane.opacityTexture = materialPlane.diffuseTexture;
			materialPlane.backFaceCulling = false
			materialPlane.zOffset = -3;

			// check if we are under a mesh
			var pickInfo = _this.scene.pick(_this.scene.pointerX, _this.scene.pointerY, (mesh) => { 
				return mesh === selectedPart; 
			});

			if (pickInfo.hit) {
				
				if(_this.logoMode == false){
					return null;
				}

				_this.removeLogoDecal(_this.logoMaterial)
				
				var decalSize = new BABYLON.Vector3(1, 0.5, 1);
				var decal = BABYLON.MeshBuilder.CreateDecal("logo_decal", selectedPart, {
					position: pickInfo.pickedPoint,
					normal: pickInfo.getNormal(true),
					size: decalSize
				});
				decal.material = materialPlane;
				_this.logoDecal = decal
			}

		},
		onDragLogo(evt){
			const _this = this;
			let selectedPart = this.selectedMesh
			
			var materialPlane = new BABYLON.StandardMaterial("texturePlane", this.scene);
			materialPlane.diffuseTexture = new BABYLON.Texture(this.logoMaterial, this.scene);
			materialPlane.diffuseTexture.hasAlpha = true;
			materialPlane.opacityTexture = materialPlane.diffuseTexture;
			materialPlane.backFaceCulling = false
			materialPlane.zOffset = -2;

			// check if we are under a mesh
			var pickInfo = _this.scene.pick(_this.scene.pointerX, _this.scene.pointerY, (mesh) => { 
				return mesh === selectedPart; 
			});
			if (pickInfo.hit) {
				_this.cameraDetachControl()
				if(_this.logoMode == false){
					return null;
				}

				if(_this.mousedown == false){
					return null
				}
				_this.removeLogoDecal(_this.logoMaterial)
				var decalSize = new BABYLON.Vector3(1, 0.5, 1);
				var decal = BABYLON.MeshBuilder.CreateDecal("logo_decal", selectedPart, {
					position: pickInfo.pickedPoint,
					normal: pickInfo.getNormal(true),
					size: decalSize
				});
				decal.material = materialPlane;
				_this.logoDecal = decal
			}
		},
		setInitLogo()
		{
			let selectedPart = this.selectedMesh
			
			var materialPlane = new BABYLON.StandardMaterial("texturePlane", this.scene);
			materialPlane.diffuseTexture = new BABYLON.Texture(this.logoMaterial, this.scene);
			materialPlane.diffuseTexture.hasAlpha = true;
			materialPlane.opacityTexture = materialPlane.diffuseTexture;
			materialPlane.backFaceCulling = false
			materialPlane.zOffset = -2;

			var pickInfo = selectedPart.centre_point

			var decalSize = new BABYLON.Vector3(1, 0.5, 1);
			var decal = BABYLON.MeshBuilder.CreateDecal("logo_decal", selectedPart, {
				position: pickInfo.pickedPoint,
				normal: pickInfo.getNormal(true),
				size: decalSize
			});
			decal.material = materialPlane;
			this.logoDecal = decal
		},
		removeLogoDecal(logo = null){
			if(this.logoDecal)
			{
				this.setLogoMaterial(logo)
				this.logoDecal.dispose()
				this.logoDecal = null
			}
		},
		takeScreenshot(item = null){
			let dataURL = null
			let quality = 650
			if(item)
			{
				let socket = item.part.relatedSocketBtn;
				let zoom_point = this.scene.getMeshByID(socket.zoom_point_id) ?? this.scene.getMeshByID('zoom_point_SOCKET_' + item.name + '_R')
				let socket_btn = this.scene.getMeshByID(socket.name) ?? this.scene.getMeshByID('btn_SOCKET_' + item.name + '_R')
				this.camera2.target = socket_btn
				this.camera2.position = zoom_point.absolutePosition
				this.camera2.radius -= 3
			}
			else
			{
				var position = BABYLON.Vector3.Zero()
				this.camera2 = new BABYLON.ArcRotateCamera("camera", Math.PI / 2, Math.PI / 2, 50, position, this.scene);
				this.camera2.minZ = 0.01;
				this.camera2.radius = 30;
				quality = 800
			}

			BABYLON.Tools.CreateScreenshotUsingRenderTarget(this.engine, this.camera2, quality, function(data){
				dataURL = data
			})
			
			return dataURL
		},
		setPartColor(color, selectedMesh = null)
		{
			if(selectedMesh)
			{
				this.selectedMesh = selectedMesh
				this.selectedMesh.material.albedoColor = new BABYLON.Color4.FromInts(color.r, color.g, color.b, color.a).toLinearSpace()
			}
			else if(this.selectedMesh)
			{
				this.selectedMesh.material.albedoColor = new BABYLON.Color4.FromInts(color.r, color.g, color.b, color.a).toLinearSpace()
			}
		},
		applySpecialMaterial(mask_url,selectedMesh = null)
		{
			let special_mat = new BABYLON.Texture(mask_url, this.scene)
			if(selectedMesh)
			{
				this.selectedMesh = selectedMesh
				this.selectedMesh.material.albedoColor = this.noColor
				this.selectedMesh.material.albedoTexture = special_mat
				this.selectedMesh.material.albedoTexture.uScale = 18;
				this.selectedMesh.material.albedoTexture.vScale = 18;
			}
			else if(this.selectedMesh)
			{
				this.selectedMesh.material.albedoColor = this.noColor
				this.selectedMesh.material.albedoTexture = special_mat
				this.selectedMesh.material.albedoTexture.uScale = 18;
				this.selectedMesh.material.albedoTexture.vScale = 18;
			}
		},
		async applyPerforation(mask_url, rgba = [], selectedMesh = null)
		{
			return new Promise((resolve, reject) => {
				if(selectedMesh)
				{
					this.selectedMesh = selectedMesh
					this.selectedMesh_2 = this.scene.getMeshByID(this.selectedMesh.id + '.' + this.selectedMesh.id)
				}
					
				if(!rgba.length>0)
					rgba = [0,0,0]
				resolve(this.loadPerforation(mask_url, rgba))
			})
		},
		getPartSpec(item)
		{
			return this.partSpecs.find((part) => part.material_no_web === item.socketInfo.material_no_web)
		},
		resetCustomization()
		{
			let part_spec = this.getPartSpec(this.selectedMesh)
			let color = null
			color = this.mainColor

			switch(this.selectedMesh.socketInfo.material_value)
			{
			case MATERIAL_PVC:
				this.selectedMesh.material.albedoTexture = null
				break;
			case MATERIAL_OTHERS: // leather
				this.selectedMesh.material.albedoTexture = this.leather_mat
				this.selectedMesh.material.albedoTexture.uScale = 18;
				this.selectedMesh.material.albedoTexture.vScale = 18;
				break;
			default: // leather
				this.selectedMesh.material.albedoTexture = this.leather_mat
				this.selectedMesh.material.albedoTexture.uScale = 18;
				this.selectedMesh.material.albedoTexture.vScale = 18;
			}

			this.selectedMesh.material.albedoColor = color
			this.resetMesh2()
		},
		resetMesh2()
		{
			if(this.selectedMesh_2)
				this.selectedMesh_2.dispose()
		},
		async loadPerforation(url, rgba = ''){
			return new Promise((resolve, reject) => {
				var imageMask = new Image();
				imageMask.crossOrigin = "Anonymous";
				let _this = this

				imageMask.onload = function () {
					resolve(_this.drawImage(this, rgba));
				};
				imageMask.src = url
			})
		},
		async drawImage(imageObj, rgba){
			return new Promise((resolve, reject) => {
				var canvas = ''
				if(this.dataKey == null)
					canvas = document.getElementById("texture");
				else
					canvas = document.getElementById("texture" + this.dataKey);

				var context = canvas.getContext("2d");
				var imgWidth = imageObj.width
				var imgHeight = imageObj.height
				canvas.width = imgWidth
				canvas.height = imgHeight

				var destX = 0;
				var destY = 0;

				context.drawImage(imageObj, destX, destY);
				var imageData = context.getImageData(0, 0, imgWidth, imgHeight);
				let newColor = []
				var i

				if(rgba)
					newColor = rgba
				else
					newColor = [230, 234, 237]
				
				var hasRed = false;

				for (i = 0; i < imageData.data.length; i += 4) {
					if(imageData.data[i] >= 100 && imageData.data[i + 1] >= 0 && imageData.data[i + 2] >= 0)
					{
						hasRed = true;
						break;
					}
				}	
					
				for (i = 0; i < imageData.data.length; i += 4) {
					if(imageData.data[i] >= 0 && imageData.data[i + 1] >= 100 && imageData.data[i + 2] >= 0)
					{
						imageData.data[i+3] = 0;
					}
					
					if(imageData.data[i] >= 0 && imageData.data[i + 1] >= 0 && imageData.data[i + 2] >= 100)
					{
						if(hasRed)
						{
							imageData.data[i] = 0;
							imageData.data[i + 1] = 0;
							imageData.data[i + 2] = 0;
						}
						else
						{
							imageData.data[i] = newColor[0];
							imageData.data[i + 1] = newColor[1];
							imageData.data[i + 2] = newColor[2];
						}
						
					}

					if(imageData.data[i] >= 100 && imageData.data[i + 1] >= 0 && imageData.data[i + 2] >= 0)
					{
						imageData.data[i] = newColor[0];
						imageData.data[i + 1] = newColor[1];
						imageData.data[i + 2] = newColor[2];
					}
				}

				this.resetMesh2()
				// overwrite original image
				context.putImageData(imageData, 0, 0);
				let perforation = new BABYLON.Texture(canvas.toDataURL(), this.scene,false,false)
				this.selectedMesh_2 = this.selectedMesh.clone(this.selectedMesh.id)
				var mat2 = new BABYLON.StandardMaterial("mesh", this.scene);
				perforation.hasAlpha = true;
				mat2.diffuseTexture = perforation;
				mat2.diffuseTexture.uScale = 8;
				mat2.diffuseTexture.vScale = 7;
				mat2.useAlphaFromDiffuseTexture = true;
				this.selectedMesh_2.material = mat2
				resolve(true)
			})
		},
		getNextSocketBtn(is_increase)
		{
			let index = this.socketBtns.findIndex((item)=> item.id == this.selectedSocket.id)
			if(is_increase)
				index = index + 1
			else
				index = index - 1

			if(index == this.socketBtns.length)
				index = 0
			else if(index < 0)
				index = this.socketBtns.length - 1
			
			return this.socketBtns[index]

		},
		onClickOutside(evt){
			const _this = this;
			let selectedPart = this.selectedMesh
			
			// check if we are under a mesh
			var pickInfo = _this.scene.pick(_this.scene.pointerX, _this.scene.pointerY, (mesh) => {
				return mesh === selectedPart; 
			});
			if (pickInfo.hit) {
				if(_this.mousedown == false){
					return null
				}
				_this.mouseMove = false
			}
			else
			{
				if(_this.mouseMove || !_this.isZoom)
				{
					_this.mouseMove = false
					return null
				}

				_this.mouseMove = false
				this.$emit('cancel-zoom')
			}
		},
		removeClickOutsideEvent()
		{
			console.log("Removing event listener click outisde")
			const _this = this;
			this.canvas.removeEventListener("pointerdown", this.checkMouseDown, true);
			this.canvas.removeEventListener("pointermove", this.checkMouseMove, true);
			this.canvas.removeEventListener("pointerup", this.checkMouseUp, true);
		},
		checkMouseDown()
		{
			this.mouseMove = false
			this.mousedown = true
		},
		checkMouseMove()
		{
			this.mouseMove = true
		},
		checkMouseUp()
		{
			this.onClickOutside()
			this.mousedown = false
		}
	}
}
</script>
<style>
canvas{
	outline: none;
}
</style>